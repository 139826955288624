import { useState, useEffect, useCallback } from 'react';
import { Spinner } from 'react-bootstrap';

import useRequest from 'hooks/useRequest';
import Item from './Item';
import PlayModal from 'components/ui/playModal/PlayModal';

import styles from './processed.module.scss';

const Processed = () => {
	const [processedItems, setProcessedItems] = useState([]);

	// edit states
	const [showPlayModal, setShowPlayModal] = useState(false);
	const [playItemId, setPlayItemId] = useState(null);

	const { request, loading } = useRequest();

	const fetchProcessedItems = useCallback(async () => {
		const response = await request(
			{
				url: '/jobs/fetch-all',
			},
			true
		);

		if (response?.result) {
			const processedItems = response.dbResult.sort(
				(a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
			);
			setProcessedItems(processedItems);
		}
	}, [request]);

	useEffect(() => {
		fetchProcessedItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const playClickHandler = (name) => {
		setPlayItemId(name);
		setShowPlayModal(true);
	};

	return (
		<section className="user-dashboard">
			<div className="dashboard-outer">
				<div className="upper-title-box">
					<h3>İşlemler</h3>
				</div>

				<div className="row">
					<div className="col-lg-12">
						{/* <!-- Ls widget --> */}
						<div className="ls-widget">
							<div className="tabs-box">
								<div className="widget-title">
									<h4>İşlemler</h4>

									{/* <div className="chosen-outer">
										<FilterSelect
											options={filterOptions}
											value={selectedFilter}
											onChange={setSelectedFilter}
										/>
									</div> */}
								</div>

								<div className="widget-content">
									<div className="table-outer">
										<table className="default-table manage-job-table">
											<thead>
												<tr>
													<th>İsim</th>
													<th className="text-center">Ses Dili</th>
													<th className="text-center">Kişi Sayısı</th>
													<th className="text-center">Aksiyon</th>
												</tr>
											</thead>

											<tbody>
												{loading && (
													<tr>
														<td colSpan={5}>
															<div className={styles.spinner}>
																<Spinner animation="border" variant="primary" />
															</div>
														</td>
													</tr>
												)}
												{!loading && processedItems.length === 0 && (
													<tr>
														<td colSpan={5}>
															<div className={styles.spinner}>Bulunamadı!</div>
														</td>
													</tr>
												)}
												{!loading &&
													processedItems.map((item) => (
														<Item
															key={item.name}
															item={item}
															playClickHandler={playClickHandler}
														/>
													))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* EDIT COMPONENTS */}

			{showPlayModal && (
				<PlayModal
					closeHandler={() => {
						setShowPlayModal(false);
						setPlayItemId(null);
					}}
					data={processedItems.find((i) => i.name === playItemId)}
				/>
			)}
		</section>
	);
};

export default Processed;
