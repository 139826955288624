import * as yup from 'yup';

const supportedFormats = ['audio/mpeg', 'audio/wav'];

const schema = yup.object().shape({
	audioFile: yup
		.mixed()
		.test('fileType', 'Bu alan gereklidir!', (fileList) =>
			fileList?.length === 1 ? supportedFormats.includes(fileList[0].type) : false
		),
	name: yup.string().required('Bu alan gereklidir!').min(3, 'Çok kısa!').max(30, 'Çok uzun!'),
	language: yup.mixed().test('isSelected', 'Bu alan gereklidir!', (selectedLanguage) => !!selectedLanguage?.value),
	numberOfSpeaker: yup.mixed().test('isSelected', 'Bu alan gereklidir!', (selected) => !!selected?.value),
});

export default schema;
