import { useEffect } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';

import logo from 'assets/images/logo-black.png';

const Header = () => {
	useEffect(() => {
		function scrollHandler() {
			if ($('.main-header').length) {
				const windowPosition = $(window).scrollTop();
				const siteHeader = $('.main-header');
				const scrollLink = $('.scroll-to-top');

				if (windowPosition > 1) {
					siteHeader.addClass('fixed-header animated slideInDown');
					scrollLink.fadeIn(300);
				} else {
					siteHeader.removeClass('fixed-header animated slideInDown');
					scrollLink.fadeOut(300);
				}
			}
		}
		scrollHandler();
		$(window).on('scroll', scrollHandler);
		return () => {
			$(window).off('scroll', scrollHandler);
		};
	}, []);

	useEffect(() => {
		if ($('#toggle-user-sidebar').length) {
			$('#toggle-user-sidebar, .mobile-nav-toggler .navbar-trigger').on('click', addClass);
			$('.user-sidebar .sidebar-inner .navigation li').on('click', removeClass);
			$('.sidebar-backdrop').on('click', removeClass);
		}

		function addClass() {
			$('body').toggleClass('user-sidebar-active');
		}

		function removeClass() {
			$('body').removeClass('user-sidebar-active');
		}

		return () => {
			$('#toggle-user-sidebar, .mobile-nav-toggler .navbar-trigger').off('click', addClass);
			$('.user-sidebar .sidebar-inner .navigation li').off('click', removeClass);
			$('.sidebar-backdrop').off('click', removeClass);
		};
	}, []);

	return (
		/* <!-- Main Header--> */
		<header className="main-header header-shaddow">
			<div className="container-fluid">
				{/* <!-- Main box --> */}
				<div className="main-box">
					{/* <!--Nav Outer --> */}
					<div className="nav-outer">
						<div className="logo-box">
							<div className="logo">
								<Link to="/">
									<img src={logo} alt="logo" title={'Dashboard'} />
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <!-- Mobile Header --> */}
			<div className="mobile-header">
				<div className="logo">
					<Link to="/">
						<img src={logo} alt="logo" title={'Dashboard'} />
					</Link>
				</div>

				{/* <!--Nav Box--> */}
				<div className="nav-outer clearfix">
					<div className="outer-box">
						<button id="toggle-user-sidebar" className="mobile-nav-toggler navbar-trigger">
							<span className="flaticon-menu-1"></span>
						</button>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
