import axios from 'axios';
import { Fragment, useState, useEffect, useRef } from 'react';
import { Modal, Spinner, Alert } from 'react-bootstrap';
import ReactAudioPlayer from 'react-audio-player';
import { MdEdit } from 'react-icons/md';
import { HiCheck, HiDownload } from 'react-icons/hi';

import styles from './play-modal.module.scss';

const PlayModal = ({ closeHandler, data }) => {
	const audioRef = useRef(null);
	const transcriptRef = useRef(null);

	const [transcript, setTranscript] = useState([]);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [canPlay, setCanPlay] = useState(false);
	const [speakersNames, setSpeakersNames] = useState({});
	const [newSpeakerName, setNewSpeakerName] = useState('');
	const [editKey, setEditKey] = useState(null);

	useEffect(() => {
		const fetchTranscript = async () => {
			try {
				setErrorMessage('');
				setLoading(true);
				const response = await axios.request({
					url: data.transcriptFileUri,
				});
				setLoading(false);
				prepareTranscript(response.data?.results);
			} catch (error) {
				setLoading(false);
				setErrorMessage(error.message);
			}
		};

		fetchTranscript();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const prepareTranscript = (transcript) => {
		// const fullTranscript = transcript?.transcripts[0]?.transcript;
		let customTranscript = [];
		const speakers = new Set();
		if (transcript) {
			let currentSpeaker = -1;
			let index = -1;
			transcript.items.forEach((item) => {
				const startTime = +item.start_time;
				const endTime = +item.end_time;
				const content = item.alternatives[0].content;
				const speaker = +getSpeaker(startTime, endTime, transcript.speaker_labels.segments);
				const newObj = {
					startTime,
					endTime,
					content,
					speaker,
				};
				if (speaker !== -1 && speaker !== currentSpeaker) {
					index++;
					customTranscript[index] = [];
					currentSpeaker = speaker;
					speakers.add(speaker);
				}
				customTranscript[index].push(newObj);
			});
		}

		const speakersNames = {};
		speakers.forEach((i) => {
			speakersNames[i] = `Konuşmacı ${i}`;
		});
		setSpeakersNames(speakersNames);
		setTranscript(customTranscript);
	};

	const wordClickHandler = (startTime) => {
		if (canPlay && audioRef?.current?.audioEl?.current) {
			const el = audioRef.current?.audioEl?.current;
			el.currentTime = startTime;
			el.play();
		}
	};

	const listenHandler = (currentTime) => {
		const spans = transcriptRef?.current?.querySelectorAll('span');

		for (const span of spans) {
			const startTime = +span.getAttribute('start-time');
			const endTime = +span.getAttribute('end-time');
			if (currentTime >= startTime && currentTime <= endTime) {
				span.style.backgroundColor = 'yellow';
				break;
			} else {
				span.style.backgroundColor = '';
			}
		}
	};

	const editClickHandler = (key, speakerNo) => {
		if (!!editKey) return;
		setEditKey({ key, speakerNo });
		setNewSpeakerName(speakersNames[speakerNo]);
	};

	const changeSpeakerNameHandler = () => {
		let isDuplicate = false;
		for (const [key, value] of Object.entries(speakersNames)) {
			if (+key === +editKey.speakerNo) continue;
			if (value === newSpeakerName) {
				isDuplicate = true;
				break;
			}
		}
		if (isDuplicate) return;
		const newSpeakersNames = { ...speakersNames };
		newSpeakersNames[editKey.speakerNo] = newSpeakerName;
		setEditKey(null);
		setSpeakersNames(newSpeakersNames);
	};

	const downloadHandler = () => {
		const data = [];
		transcript.forEach((arr) => {
			const speakerNo = arr[0].speaker;
			const speakerName = speakersNames[speakerNo];
			let line = `${speakerName}: `;

			arr.forEach((item) => {
				line = line + (item.startTime ? ' ' : '') + item.content;
			});
			data.push(line + '\n');
		});

		const blob = new Blob(data, { type: 'text/plain' });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.style.display = 'none';
		a.href = url;
		a.download = 'transcription.doc';
		a.click();
		URL.revokeObjectURL(url);
		a.remove();
	};

	return (
		<Modal show={true} size="xl" onHide={closeHandler} className={styles.modal} dialogClassName={styles.dialog}>
			<Modal.Header closeButton className={styles.header}>
				<Modal.Title>{data.name}</Modal.Title>
			</Modal.Header>
			<Modal.Body className={styles.body}>
				{loading && (
					<div className={styles.spinner}>
						<Spinner animation="border" variant="primary" />
					</div>
				)}
				{!loading && (
					<>
						<ReactAudioPlayer
							src={process.env.REACT_APP_API + data.audioPath}
							controls
							className={styles.audio}
							onCanPlayThrough={setCanPlay.bind(null, true)}
							onSeeked={() => {}}
							listenInterval={300}
							onListen={listenHandler}
							ref={audioRef}
						/>
						<div className={styles.transcriptWrapper} ref={transcriptRef}>
							{transcript.map((arr, idx) => {
								const speakerNo = arr[0].speaker;
								const key = arr.reduce((prev, curr) => prev + curr.content, '') + speakerNo + idx;

								return (
									<p key={key} className={styles.paragraph}>
										{editKey && editKey.key === key ? (
											<span className={styles.editInputWrapper}>
												<input
													type="text"
													value={newSpeakerName}
													onChange={(e) => setNewSpeakerName(e.target.value)}
													autoFocus
												/>
												<span onClick={changeSpeakerNameHandler}>
													<HiCheck />
												</span>
											</span>
										) : (
											<strong>
												<button
													className={styles.editBtn}
													title="konuşmacı adını yeniden adlandır"
													onClick={editClickHandler.bind(null, key, speakerNo)}
												>
													<MdEdit />
												</button>{' '}
												{speakersNames[speakerNo]}:
											</strong>
										)}

										{arr.map((item, idx2) => {
											const key2 =
												item.startTime.toString() +
												item.endTime.toString() +
												item.content +
												idx2;
											return (
												<Fragment key={key2}>
													{item.startTime ? ' ' : ''}
													<span
														start-time={item.startTime.toString()}
														end-time={item.endTime.toString()}
														speaker={item.speaker.toString()}
														onClick={wordClickHandler.bind(null, item.startTime)}
													>
														{item.content}
													</span>
												</Fragment>
											);
										})}
									</p>
								);
							})}
						</div>
					</>
				)}
				{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
			</Modal.Body>
			<Modal.Footer className={styles.footer}>
				<button className="theme-btn btn-style-three" onClick={closeHandler}>
					Kapat
				</button>
				<button className="theme-btn btn-style-one" onClick={downloadHandler}>
					İndir <HiDownload className="ms-1" size={20} />
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default PlayModal;

const getSpeaker = (startTime, endTime, segments) => {
	let speaker = -1;
	if (!startTime || !endTime) return speaker;

	for (const segment of segments) {
		const segStartTime = +segment.start_time;
		const segEndTime = +segment.end_time;
		const lSpeaker = +segment.speaker_label.split('_')[1] + 1;
		if (startTime >= segStartTime && endTime <= segEndTime) {
			speaker = lSpeaker;
			break;
		}
	}

	return speaker;
};
