import { useState, useCallback } from 'react';
import axios from 'axios';

const useRequest = () => {
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const request = useCallback(async (config, shouldLoad = true) => {
		try {
			if (shouldLoad) setLoading(true);
			const response = await axios.request(config);
			if (shouldLoad) setLoading(false);
			if (response.data.result) {
				return response.data;
			} else {
				throw new Error(response.data.message);
			}
		} catch (error) {
			if (shouldLoad) setLoading(false);
			setErrorMessage(error.message);
			console.log(error.message);
		}
	}, []);
	return { request, setLoading, setErrorMessage, loading, errorMessage };
};

export default useRequest;
