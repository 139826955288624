import axios from 'axios';
import Routes from './routes/Routes';
// import styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/sass/main.scss';
import 'assets/css/responsive.css';
import 'app.scss';

axios.defaults.baseURL = process.env.REACT_APP_API;

function App() {
	return <Routes />;
}

export default App;
