import { Outlet } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
// import Footer from './Footer';

const Layout = () => {
	return (
		<main>
			<div className="page-wrapper dashboard ">
				{/* <!-- Header Span --> */}
				<span className="header-span"></span>
				<Header />
				{/* <!-- Sidebar Backdrop --> */}
				<div className="sidebar-backdrop"></div>
				<Sidebar />
				<Outlet />
				{/* <Footer /> */}
			</div>
		</main>
	);
};
export default Layout;
