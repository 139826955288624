import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

const Sidebar = () => {
	const { pathname } = useLocation();

	return (
		/* <!-- User Sidebar --> */
		<aside className="user-sidebar">
			<nav className="sidebar-inner">
				<ul className="navigation">
					{items.map((item) => (
						<li key={item.title} className={classNames({ active: pathname === item.to })}>
							<Link to={item.to}>
								{' '}
								<i className={item.icon}></i> {item.title}
							</Link>
						</li>
					))}
				</ul>
			</nav>
		</aside>
		/* <!-- End User Sidebar --> */
	);
};

const items = [
	{ title: 'Studio', to: '/', icon: 'la la-home' },
	{ title: 'İşlemler', to: '/processed', icon: 'la la-file-invoice' },
];

export default Sidebar;
