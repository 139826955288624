import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Spinner } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import PlayModal from 'components/ui/playModal/PlayModal';
import SingleSelect from 'components/ui/SingleSelect';
import schema from './schema';
import useRequest from 'hooks/useRequest';
import styles from './studio.module.scss';

const Studio = () => {
	const [processedData, setProcessedData] = useState({});
	const [showPlayModal, setShowPlayModal] = useState(false);

	const {
		register,
		control,
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
	} = useForm({ resolver: yupResolver(schema) });
	const { request, setErrorMessage, loading, errorMessage } = useRequest();

	let audioFile = null;
	if (watch()?.audioFile?.length > 0) {
		audioFile = watch()?.audioFile[0];
	}

	const submitHandler = async (data) => {
		const formData = new FormData();
		formData.append('audioFile', data.audioFile[0]);
		formData.append('name', data.name);
		formData.append('speakerCount', data.numberOfSpeaker.value);
		formData.append('languageCode', data.language.value);
		postData(formData);
	};

	const postData = async (formData) => {
		setErrorMessage('');
		const response = await request({
			url: '/jobs/transcribe',
			method: 'post',
			data: formData,
		});

		if (response?.result) {
			setProcessedData(response.output);
			setShowPlayModal(true);
		}
	};

	const closePlayModalHandler = () => {
		setShowPlayModal(false);
		// reset state
		setValue('audioFile', null);
		setValue('name', '');
		setValue('numberOfSpeaker', '');
		setValue('language', '');
	};

	return (
		<section className="user-dashboard">
			{loading && (
				<SweetAlert title="Lütfen bekleyiniz!" confirmBtnCssClass="d-none" onConfirm={() => {}}>
					Verileriniz işleniyor, birkaç dakika sürebilir.
					<br />
					<Spinner animation="border" className="mt-4" />
				</SweetAlert>
			)}
			{showPlayModal && <PlayModal data={processedData} closeHandler={closePlayModalHandler} />}
			<div className="dashboard-outer">
				<div className="upper-title-box">
					<h3>Studio</h3>
				</div>
				<form onSubmit={handleSubmit(submitHandler)}>
					<div className="row">
						<div className="col-lg-12">
							<div className="ls-widget">
								<div className="tabs-box">
									<div className="widget-content">
										<div className="uploading-outer">
											<div className={styles.col}>
												<div className={`${styles.row} mt-5`}>
													<div className="uploadButton">
														<input
															className="uploadButton-input"
															type="file"
															accept=".mp3, .wav"
															id="upload"
															multiple={false}
															{...register('audioFile')}
														/>
														<label
															className="uploadButton-button ripple-effect"
															htmlFor="upload"
														>
															Ses dosyası seçiniz
														</label>
													</div>
													<div className={styles.col}>
														<div className="text mb-2">
															Uygun dosyalar .mp3 ve .wav'dir.
														</div>
														{audioFile && <div>{audioFile.name}</div>}
													</div>
												</div>
												{errors.audioFile && (
													<p className="form-error mb-0 mt-1">{errors.audioFile.message}</p>
												)}
											</div>
										</div>
										<section className="default-form">
											<div className="row">
												{/* <!-- Input --> */}
												<div className="form-group col-lg-12 col-md-12 mb-3">
													<label>İsim*</label>
													<input type="text" placeholder="" {...register('name')} />
													<p className="mb-0">Ses sentizi için hatırlatıcı bir isim yazın</p>
													{errors.name && (
														<p className="form-error mb-0">{errors.name.message}</p>
													)}
												</div>

												{/* <!-- Input --> */}
												<div className="col-lg-6 col-md-12">
													<label className="select-label">Ses Dili*</label>
													<Controller
														control={control}
														name="language"
														render={({ field: { onChange, value } }) => (
															<SingleSelect
																options={languages}
																onChange={onChange}
																value={value}
															/>
														)}
													/>
													{errors.language && (
														<p className="form-error mt-n5 mb-3">
															{errors.language.message}
														</p>
													)}
												</div>
												{/* <!-- Input --> */}
												<div className="col-lg-6 col-md-12">
													<label className="select-label">Konuşmacı Sayısı*</label>
													<Controller
														control={control}
														name="numberOfSpeaker"
														render={({ field: { onChange, value } }) => (
															<SingleSelect
																options={numberOfSpeakerList}
																onChange={onChange}
																value={value}
															/>
														)}
													/>
													{errors.numberOfSpeaker && (
														<p className="form-error mt-n5 mb-3">
															{errors.numberOfSpeaker.message}
														</p>
													)}
												</div>
											</div>
										</section>
										{errorMessage && <Alert variant="danger">Hata: {errorMessage}</Alert>}
										<button className={`theme-btn btn-style-one ${styles.submitBtn}`} type="submit">
											Gönder
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</section>
	);
};

export default Studio;

const languages = [
	{ label: 'Türkçe', value: 'tr-TR' },
	{ label: 'İngiliz İngilizcesi', value: 'en-GB' },
	{ label: 'Amerikan İngilizcesi', value: 'en-US' },
];

const numberOfSpeakerList = Array(10)
	.fill(0)
	.map((_, idx) => ({ label: idx + 1, value: idx + 1 }));
