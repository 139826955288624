import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';

import Layout from 'components/layout/Layout';
// import pages
import Studio from 'pages/studio/Studio';
import Processed from 'pages/processed/Processed';

const RoutesComponent = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route element={<Layout />}>
					<Route path="/" element={<Studio />} />
					<Route path="/processed" element={<Processed />} />
				</Route>

				<Route path="/*" element={<Navigate to={'/'} />} replace />
			</Routes>
		</BrowserRouter>
	);
};

export default RoutesComponent;
