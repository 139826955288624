import Select from 'react-select';

const SingleSelect = ({ onChange, defaultValue, value, options, placeholder }) => {
	return (
		<Select
			defaultValue={defaultValue}
			options={options}
			onChange={onChange}
			value={value}
			placeholder={placeholder || 'Seçiniz...'}
			styles={customStyles}
			menuPortalTarget={document.body}
			noOptionsMessage={() => 'Seçenek yok'}
		/>
	);
};

const customStyles = {
	container: (provided, state) => ({
		...provided,
		marginBottom: '30px',
	}),
	control: (provided, state) => {
		return {
			...provided,
			background: state.isFocused ? 'white' : '#F0F5F7',
			border: '1px solid #F0F5F7',
			borderRadius: ' 8px',
			height: '60px',
			'&:hover': {
				border: '1px solid #F0F5F7',
			},
		};
	},
	singleValue: (provided, state) => ({
		...provided,
		paddingLeft: 10,
	}),
	placeholder: (provided, state) => ({
		...provided,
		paddingLeft: '10px',
	}),
	input: (provided, state) => ({
		...provided,
		paddingLeft: '10px',
	}),
	valueContainer: (provided, state) => ({
		...provided,
		'&>div': {
			width: 'auto !important',
		},
	}),
	multiValue: (provided, state) => ({
		...provided,
		borderRadius: '8px',
		background: '#1967d21a',
		color: '#1967d2',
		'& div': {
			color: '#1967d2',
			fontWeight: '500',
			fontSize: '13px',
			lineHeight: 'normal',
		},
	}),
	menuPortal: (base) => ({ ...base, zIndex: 99999 }),
};

export default SingleSelect;
