import moment from 'moment';
import 'moment/locale/tr';
import styles from './processed.module.scss';

moment().locale('tr');

const Item = ({ item, playClickHandler }) => {
	let language = '';
	switch (item.languageCode) {
		case 'en-GB':
			language = 'İngiliz İnglizcesi';
			break;
		case 'en-US':
			language = 'American İnglizcesi';
			break;
		case 'tr-TR':
			language = 'Türkçe';
			break;
		default:
			break;
	}
	return (
		<tr>
			<td>
				<h6 className="text-nowrap">{item.name}</h6>
				<span className="info text-nowrap">
					{moment(new Date(item.date)).format('MMMM Do YYYY, h:mm:ss a')}
				</span>
			</td>
			<td className="applied text-center">{language}</td>
			<td className="text-center text-nowrap">{item.speakerCount} kişi</td>
			<td className={styles.actionsTD}>
				<div className="option-box">
					<ul className="option-list">
						<li>
							<button data-text="İncele" onClick={playClickHandler.bind(null, item.name)}>
								<span className="la la-eye"></span>
							</button>
						</li>
					</ul>
				</div>
			</td>
		</tr>
	);
};

export default Item;
